.Datamanagement-first img {
  width: 100%;
  display: flex;
  height: 80vh;
}
.Datamanagement-first h2 {
  text-align: center;
  position: absolute;
  font-family: "Philosopher", Sans-serif;
  letter-spacing: 1.5px;
  font-size: 28px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.datamanagement-middle {
  padding: 5%;
  margin-top: 3%;
  display: flex;
}

.datamanagement-middle-content,
.datamanagement-middle-img {
  flex: 1;
  background-color: #f3f2f2;
  padding: 1%;
}
.datamanagement-middle-content span {
  color: #02678b;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.datamanagement-middle-content h3 {
  margin-bottom: 1%;
  width: 50%;
  font-family: "Poppins", Sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.4em;
  letter-spacing: 0.1px;
}
.datamanagement-middle-img img {
  max-width: 100%;
  height: auto;
  position: relative;
  top: -12%;
  left: -5%;
}
.datamanagement-last-div {
  display: flex;
  margin-top: -5%;
  padding: 5%;
}
.datamanagement-last-left,
.datamanagement-last-right {
  flex: 1;
  background-color: #f3f2f2;
  padding: 1%;
}
.datamanagement-last-left h3 {
  font-weight: bold;
}
.datamanagement-last-left img {
  max-width: 65%;
  height: 100%;
}

.datamanagement-last-right h4 {
  margin-bottom: 3%;
  margin-left: 4%;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.datamanagement-last-content-p h3 {
  margin-bottom: 4%;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.datamanagement-last-content-p p {
  text-align: justify;
  font-family: "Mulish", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  margin: 0 0 1em 0;
  letter-spacing: 0.4px;
}
.datamanagement-services {
  position: relative;
  left: 10%;
}
.datamanagement-services li {
  line-height: 2.1;
  font-family: "Poppins", Sans-serif;
}
.datamanagement-content-two h3 {
  margin-bottom: 4%;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.datamanagement-content-two ul {
  margin-left: 10%;
  line-height: 2.1;
  font-family: "Poppins", Sans-serif;
}

.datamanagements-middle {
  padding: 5%;
  margin-top: -5%;
  display: flex;
}

.datamanagements-middle-content,
.datamanagements-middle-img {
  flex: 1;
}
.datamanagements-middle-content span {
  color: #02678b;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.datamanagements-middle-content h3 {
  margin-bottom: 2%;
  /* width: 50%; */
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.1px;
}
.datamanagements-middle-img img {
  max-width: 100%;
  height: auto;
}
.datamanagements-last-left,
.datamanagements-last-right {
  flex: 1;
  padding: 1%;
}
.datamanagements-last-right h4 {
  margin-bottom: 3%;
  margin-left: 4%;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.datamanagements-content-two h3 {
  margin-bottom: 4%;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.datamanagements-content-two ul {
  margin-left: 10%;
  font-size: 20px;
  line-height: 2.1;
  font-family: "Poppins", Sans-serif;
}
.datamanagements-middle {
  padding: 5%;
  display: flex;
}

.Datamanagement-first img {
  width: 100%;
  height: auto;
}

@media (min-width: 767px) {
  .Datamanagement-first h2 {
    text-align: center;
    position: absolute;
    font-family: "Philosopher", Sans-serif;
    letter-spacing: 1.5px;
    font-size: 28px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

@media (max-width: 768px) {
  .Datamanagement-first h2 {
    font-size: 20px;
    top: 40%;
  }
  .datamanagement-middle-img img {
    left: 0;
  }
  .datamanagements-last-right h4 {
    margin-left: 0;
  }

  .datamanagement-middle,
  .datamanagements-middle,
  .datamanagement-last-div {
    flex-direction: column;
    padding: 5%;
  }

  .datamanagement-middle-content,
  .datamanagement-middle-img,
  .datamanagements-middle-content,
  .datamanagements-middle-img,
  .datamanagement-last-left,
  .datamanagement-last-right,
  .datamanagements-last-left,
  .datamanagements-last-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .datamanagement-middle-content h3,
  .datamanagements-middle-content h3,
  .datamanagement-last-left h3,
  .datamanagements-last-left h3 {
    width: 100%;
  }

  .datamanagement-middle-img img,
  .datamanagements-middle-img img,
  .datamanagement-last-left img,
  .datamanagements-last-left img {
    width: 100%;
  }

  .datamanagement-last-left img {
    max-width: none;
  }
}
