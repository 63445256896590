.bg {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  z-index: -1;
}
.text-content {
  display: flex;
  width: 100%;
  color: #fff;
  height: 100%;
  position: relative;
  left: 3%;
  justify-content: center;
  flex-direction: column;
}
.h3-our {
  font-size: 50px;
}
.text-content p {
  margin-top: 2%;
  font-size: 1.5rem;
  text-align: justify;
  line-height: 150%;
  width: 40%;
}
.about-container {
  display: flex;
  padding: 5%;
  justify-content: space-between;
  flex-direction: row;
}

.about-left {
  width: 100%;
}
.about-img {
  max-width: 70%;
  position: relative;
  left: 25%;
}
.about-left h3 {
  font-size: 1.5rem;
  margin-top: 15%;
  margin-bottom: 5%;
}

.about-left p {
  font-size: 1rem;
  text-align: justify;
  line-height: 1.8;
  margin: 0 0 1em 0;
}
.all-div {
  margin-top: 5%;
}
.course {
  text-align: center;
  width: 80%;
  margin: auto;
}
.course h1 {
  font-size: 36px;
  margin-top: -3%;
  font-weight: 600;
}
.course p {
  color: #777;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  padding: 10px;
}
.row {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
}
.course-col {
  flex-basis: 31%;
  background: #fff3f3;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 20px 12px;
  box-sizing: border-box;
  transition: 0.5s;
}

.course-col h2 {
  text-align: center;
  font-weight: 600;
  margin: 10px 0;
}
.course-col:hover {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .text-content {
    width: 90%;
    left: 5%;
  }

  .text-content p {
    width: 100%;
    font-size: 1.3rem;
  }

  .about-container {
    flex-direction: column;
    padding: 3%;
  }

  .about-left {
    width: 100%;
    margin-bottom: 5%;
  }

  .about-img {
    max-width: 100%;
    position: static;
    left: 0;
  }

  .about-left h3 {
    font-size: 1.3rem;
    margin-top: 10%;
    margin-bottom: 3%;
  }

  .about-left p {
    font-size: 1rem;
    line-height: 1.6;
  }

  .all-div {
    margin-top: 3%;
  }

  .course {
    width: 90%;
  }

  .course h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .course p {
    font-size: 12px;
    line-height: 18px;
    padding: 5px;
  }

  .row {
    flex-direction: column;
  }

  .course-col {
    flex-basis: 100%;
    margin-bottom: 3%;
  }
}
