.enterprise-first img {
  width: 100%;
  display: flex;
  height: 75vh;
  color: #000;
}
.enterprise-first h2 {
  text-align: center;
  position: absolute;
  font-family: "Philosopher", Sans-serif;
  letter-spacing: 1.5px;
  font-size: 28px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.engineer-middle-content h5 {
  color: #02678b;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.engineer-middle-content h5::after {
  content: "";
  display: block;
  width: 15%;
  height: 2px;
  background: #000;
}

.engineer-paragh ul {
  margin-left: 5%;
  margin-bottom: 2%;
}
.engineer-paragh li {
  letter-spacing: 0.8px;
  text-align: justify;
  font-size: 18px;
  line-height: 1.8;
}
