.datamanagement-services-2 {
  position: relative;
  left: 6%;
}
.datamanagement-services-2 li {
  line-height: 2.1;
  font-family: "Poppins", Sans-serif;
}
@media screen and (max-width: 768px) {
  .datamanagement-last-div {
    display: block;
  }
}
