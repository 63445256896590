.job-opening-card {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #ecf0f1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.job-opening-card:hover {
  transform: scale(1.05);
}

.job-opening-header {
  border-bottom: 1px solid #3498db;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.job-opening-header h3 {
  margin-bottom: 10px;
  color: #3498db;
}

.job-opening-content {
  color: #666;
}

.apply-now-button {
  background-color: #3498db;
  color: #fff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.apply-now-button:hover {
  background-color: #2980b9;
}
