@import url("https://fonts.googleapis.com/css2?family=Rasa:wght@400;600&display=swap");

.Top-area {
  display: flex;
  padding: 5%;
  justify-content: space-between;
  flex-direction: row;
  background-color: white;
}

.Top-Left {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.margin-top {
  font-size: 50px;
  width: 35vw;
  cursor: pointer;
}

.p-quality,
.span-achieve {
  font-size: 40px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-bottom: 4%;
}

.p-innovation {
  font-weight: 100;
  color: rgb(112, 108, 108);
  display: inline-block;
  position: relative;
}

.Top-Left p.span-achieve {
  font-family: "Roboto Mono", monospace;
}

.Top-Right {
  padding: 2%;
  width: 50%;
}

.Top-Right img {
  width: 100%;
}

.Top-Right img {
  cursor: pointer;
  transition: 0.6s;
  border-radius: 20px;
}

.Top-Right img:hover {
  transform: scale(0.9);
}

.p-quality:after,
.span-achieve:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -14px;
  left: 0;
  background-color: #646464;
  transform-origin: bottom right;
  transition: transform 0.75s ease-in-out;
}

.p-quality:hover:after {
  transform: scaleX(1);
}

.span-achieve {
  margin-top: 4%;
  font-size: 40px;
}

.span-achieve:hover:after {
  transform: scaleX(0.9);
  transform-origin: bottom left;
}

.welcome {
  display: flex;
  padding: 5%;
  justify-content: space-between;
  flex-direction: row;
  background-color: white;
}

.left-content img {
  width: 100%;
  height: auto;
}

.middle-content p {
  font-size: 20px;
  width: 80%;
  letter-spacing: 2px;
  line-height: 160%;
  text-align: justify;
  position: relative;
  left: 9%;
}

.right-content {
  display: flex;
  justify-content: flex-end;
}

.right-content img {
  width: 100%;
  height: auto;
}

.p-company {
  position: absolute;
  left: 5%;
  font-family: "Rasa", Sans-serif;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #00678b;
}

.last-div {
  display: flex;
  padding: 5%;
  justify-content: space-between;
  flex-direction: row;
  background-color: white;
}

.last-left {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list-item p {
  margin-left: 10px;
  line-height: 170%;
  font-weight: bold;
}

.last-left h4 {
  position: relative;
  width: max-content;
  font-size: 25px;
  top: -40px;
}
.datamanagement-middle-content p {
  width: 80%;
  margin-left: 4%;
  font-family: "Rasa", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  text-align: justify;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.main-container {
  display: flex;
}

.left-home {
  width: 44%;
  padding: 20px;
  position: relative;
}

.left-home p {
  font-family: "Rasa", Sans-serif;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0.2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.left-home img {
  max-width: 100%;
  cursor: pointer;
  transition: 0.6s;
  height: 100%;
}

.left-home img:hover {
  transform: scale(0.9);
}

.right-home {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.grid-item {
  flex: 1 1 48%;
  position: relative;
}

.grid-item img {
  max-width: 100%;
  cursor: pointer;
  transition: 0.6s;
  height: -webkit-fill-available;
}

.grid-item img:hover {
  transform: scale(0.9);
}

.grid-item p {
  font-family: "Rasa", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
.last-middle p {
  margin-top: -4%;
  font-family: "Rasa", Sans-serif;
  font-size: 22px;
  font-weight: 500;
  text-align: justify;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding: 8%;
  display: block;
  opacity: 0;
  animation: reveal 1s ease-in-out forwards;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @media only screen and (max-width: 768px) {

} */
@media screen and (max-width: 768px) {
  .datamanagement-middle-content p {
    width: 90%;
  }
  .main-container {
    flex-direction: column;
  }
  .p-company {
    position: relative;
  }

  .left-home,
  .right-home {
    width: 100%;
    padding: 10px;
  }

  .left-home p,
  .grid-item p {
    font-size: 24px;
  }
  .Top-area {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .Top-Left {
    width: 100%;
    text-align: center;
  }
  .margin-top {
    margin-top: 5vh;
    font-size: 30px;
    width: auto;
  }
  .p-quality,
  .span-achieve {
    font-size: 30px;
  }
  .p-innovation {
    font-size: 18px;
  }
  .Top-Right img {
    width: 80%;
    margin: 20px 0;
  }
  .welcome {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .left-content img,
  .right-content img {
    margin-top: 8%;
    margin-bottom: 20px;
  }
  .middle-content p {
    font-size: 18px;
    text-align: justify;
  }
  .last-div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .last-left {
    width: 100%;
    text-align: center;
  }
  .last-left h4 {
    font-size: 20px;
  }
  .list-item p {
    font-size: 16px;
  }
  .last-middle p {
    font-size: 18px;
    position: relative;
    margin-top: 2%;
    margin-bottom: 8%;
    left: 1%;
    width: 100%;
  }
  .riskmanagement-first h2 {
    width: 100%;
    top: 45%;
  }
}
