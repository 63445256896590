button {
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-top: 5%;
}

input {
  font-weight: 700;
  letter-spacing: 1.4px;
}

.container {
  flex: 0 1 700px;
  margin: auto;
  padding: 10px;
}

.screen {
  position: relative;
  background: #fff;
  border-radius: 15px;
}

.screen:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #00688a;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.app-title {
  flex-direction: column;
  position: relative;
  color: #00688a;
  font-size: 26px;
}

.app-title:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 50px;
  height: 3px;
  background: #00688a;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.app-form-button {
  background: none;
  border: none;
  color: #00688a;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  margin-left: 50px;
}

.app-form-button:hover {
  color: #d83838;
}
.screen-body-item img {
  display: flex;
  position: relative;
  margin-top: 8%;
  justify-content: center;
  width: 85%;
}
.screen-body-item p {
  margin-top: 8%;
  line-height: 160%;
  text-align: justify;
}
.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }
  .screen-body-item.left {
    margin-bottom: 30px;
  }
  .app-title {
    flex-direction: row;
  }
  .app-title span {
    margin-right: 12px;
  }
  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }
  .screen-body-item {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .screen-body-item {
    padding: 20px 5px;
  }

  .app-title {
    font-size: 20px;
  }

  .app-form-control,
  .app-form-button {
    width: 100%;
  }

  .app-form-button {
    margin-left: 0;
    text-align-last: end;
    margin-top: 15px;
  }

  .screen-body-item img {
    width: 100%;
    margin-top: 15px;
  }

  .screen-body-item p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.5;
  }
}
