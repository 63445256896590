.engineer-first {
  width: 100%;
  height: 70vh;
  overflow-x: hidden;
  z-index: -1;
}
.engineer-first h2 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.engineer-middle {
  padding: 5%;
  margin-top: 3%;
  display: flex;
}

.engineer-middle-content,
.engineer-middle-img {
  flex: 1;
  background-color: #f3f2f2;
  padding: 1%;
}
.engineer-middle-content span {
  color: #02678b;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1px;
}
/* .engineer-middle-content span::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10%;
  width: 90px;
  height: 2px;
  background: #000;
} */
.engineer-middle-content h4 {
  margin-top: 5%;
  width: 50%;
  font-family: "Poppins", Sans-serif;
  font-size: 31px;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.1px;
}
.engineer-middle-img img {
  max-width: 100%;
  height: auto;
  position: relative;
  top: -15%;
  left: -5%;
}
.engineer-paragh {
  margin-top: -4%;
  padding: 5%;
}
.engineer-paragh h3 {
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 500;
  margin-top: 4%;
  margin-bottom: 3%;
  letter-spacing: 0.1px;
}
.engineer-paragh p {
  font-size: 19px;
  text-align: justify;
  line-height: 2;
  margin: 0 0 1em 0;
}
.engineer-last-div {
  display: flex;
  margin-top: -5%;
  padding: 5%;
}
.engineer-last-left,
.engineer-last-right {
  flex: 1;
  background-color: #f3f2f2;
  padding: 1%;
}
.engineer-last-left {
  padding: 4%;
}
.engineer-last-right {
  padding: 4%;
  margin-left: -17%;
}
.engineer-last-left img {
  max-width: 65%;
  height: 100%;
}
.engineer-last-right h4 {
  margin-left: -10%;
  font-family: "Poppins", Sans-serif;
  margin-bottom: 2%;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1.8;
  font-size: 22px;
}
.engineer-services li {
  margin-left: -1%;
  line-height: 2.2;
}

@media screen and (max-width: 768px) {
  .engineer-first {
    height: 50vh;
  }
  .engineer-middle-content span::after {
    display: none;
  }

  .engineer-middle {
    flex-direction: column;
    padding: 3%;
  }
  .engineer-paragh li {
    margin-left: 4%;
  }

  .engineer-services li {
    margin-left: 14%;
  }

  .engineer-middle-content,
  .engineer-middle-img {
    width: 100%;
    padding: 3% 0;
  }

  .engineer-middle-content h4 {
    width: 100%;
  }

  .engineer-middle-img img {
    width: 100%;
    top: 0;
    left: 0;
  }

  .engineer-paragh {
    padding: 3%;
  }

  .engineer-last-div {
    flex-direction: column;
    padding: 3%;
  }

  .engineer-last-left,
  .engineer-last-right {
    width: 100%;
    padding: 3% 0;
    margin-left: 0;
  }

  .engineer-last-right h4 {
    margin-left: 0;
  }
}
