.datamanagement-last-right p {
  text-align: justify;
  font-family: "Mulish", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  margin: 0 0 1em 0;
  letter-spacing: 0.4px;
}
.datamanagements-last-right p {
  text-align: justify;
  font-family: "Mulish", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  margin: 0 0 1em 0;
  letter-spacing: 0.4px;
}
