.counter-div {
  padding: 4%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: aliceblue;
}
.counter-div h1 {
  background-color: #fff;
  padding: 5%;
  transition: transform 0.3s;
  color: #000;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.925);
}
.counter-div h1:hover {
  transform: scale(1.4);
  color: #d83838;
}
.counter-div p {
  font-size: 23px;
}
@media (max-width: 768px) {
  .counter-div {
    display: flex;
    text-align-last: center;
    flex-direction: column;
    padding: 12%;
  }
  .counter-div h1:hover {
    transform: scale(1.3);
  }

  .counter-div h1 {
    width: 100%;
    margin-bottom: 15%;
    transition: none;
    transform: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
  }

  .counter-div p {
    font-size: 18px;
  }
}
