.engineer-last-right span {
  margin-left: -10%;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1.9;
  font-size: 20px;
}
.engineer-services ol {
  margin-left: 8%;
  margin-top: 2%;
}
.datamanagements-last-right img {
  margin-top: 7%;
  position: relative;
  left: 11%;
  width: 450px;
}
.engineer-middle-content h2 {
  font-weight: 500;
  letter-spacing: 1.5px;
  width: 80%;
  text-align: justify;
  line-height: 1.5;
  font-size: 20px;
}
.engineer-middle-img button {
  display: flex;
}
.button-div {
  position: absolute;
  margin-top: -25%;
  right: 22%;
}
.button a {
  text-decoration: none;
}

.button {
  top: 50%;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 2%;
  background-color: #e3ebf1;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button:hover {
  background-color: #d83838;
  color: #fff;
  transform: scale(1.08);
}
.engineer-serviced p {
  text-align: justify;
  font-family: "Mulish", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
  margin: 0 0 1em 0;
  letter-spacing: 0.4px;
}

.engineer-last-right h5 {
  font-family: "Poppins", Sans-serif;
  margin-bottom: 2%;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1.8;
  font-size: 22px;
}
.engineer-last-left h3 {
  font-family: "Poppins", Sans-serif;
  margin-bottom: 4%;
  margin-top: 4%;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1.8;
  font-size: 22px;
}
.right-images img {
  position: relative;
  margin-top: 12%;
  width: 100%;
}
.engineer-last-left li {
  line-height: 1.5;
  margin: 0.5rem 0 0 2rem;
}
@media screen and (max-width: 768px) {
  .datamanagements-last-left,
  .datamanagements-last-right {
    display: contents;
  }
  .datamanagements-last-right img {
    display: none;
  }
  .button-div {
    position: absolute;
    margin-top: -40%;
    right: 31%;
  }
  .engineer-middle-content p::after {
    display: none;
  }
  .engineer-middle-content h2 {
    width: 100%;
    margin-top: 4%;
  }
  .engineer-last-left img {
    min-width: -webkit-fill-available;
  }
  .engineer-last-right span {
    margin-left: 2%;
  }
  .datamanagement-last-div {
    display: block;
  }
}
