.Login-container {
  margin: 3%;
  margin-top: 7%;
  padding: 2%;
  width: 70%;
  margin-left: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
}

.Login-image img {
  margin-left: 16%;
  width: 70%;
}

.Login-content {
  width: 100%;
  padding: 5%;
}

.Login-container h1 {
  margin-bottom: 8%;
  letter-spacing: 2px;
  text-align: start;
}

.Login-container input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
}

.Login-container button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Login-container button:hover {
  background-color: #d83838;
}

.Login-container p {
  margin-top: 10px;
  color: red;
  text-align: start;
}

/* Admin */

.admin-container {
  padding: 5%;
}
.admin-form {
  display: flex;
  margin-left: 5%;
  flex-direction: column;
}
.admin-container h1 {
  font-family: "Philosopher", Sans-serif;
  letter-spacing: 3px;
  margin-bottom: 5%;
  text-align: center;
}

.form-container {
  opacity: 0;
  animation: fadeIn 1s forwards;
  margin-bottom: 20px;
}
.form-container h2 {
  letter-spacing: 2px;
  font-family: "Mulish", Sans-serif;
  margin-bottom: 3%;
}
.job-list-container h2 {
  letter-spacing: 2px;
  font-family: "Mulish", Sans-serif;
  margin-top: 4%;
  margin-bottom: 2%;
}
.job-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

.form-container label {
  font-size: 25px;
  color: #333;
  text-align: left;
}

.form-container input,
textarea {
  display: flex;
  padding: 12px;
  margin-top: 2%;
  margin-bottom: 20px;
  width: 50%;
  box-sizing: border-box;
  border: 1px solid #838181;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-container button {
  width: 15%;
  padding: 12px;
  margin-top: -0%;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.form-container button:hover {
  background-color: #0056b3;
}

.job-list-container {
  opacity: 0;
  animation: slideDown 1s forwards;
}

.job-list {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border: 1px solid #c1bfbf;
  width: 20%;
  text-align: justify;
  padding: 8px;
  text-align: left;
}
th {
  text-align: center;
}
.btn-edit-delete button {
  margin: 2px;
  color: #004e89;
  border: none;
}

.logout-btn {
  padding: 10px;
  width: 10%;
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  position: fixed;
  top: 10px;
  right: 10px;
}

.footer-img {
  width: 100%;
  height: 100%;
  display: flex;
}
.footer {
  bottom: 0;
  position: relative;
  width: 100%;
  height: 250px;
  overflow-x: hidden;
}
.footer_paragh {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}
.cycle {
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhyLGwEUVwPK6Vi8xXMymsc-ZXVwLWyXhogZxbcXQYSY55REw_0D4VTQnsVzCrL7nsyjd0P7RVOI5NKJbQ75koZIalD8mqbMquP20fL3DxsWngKkOLOzoOf9sMuxlbyfkIBTsDw5WFUj-YJiI50yzgVjF8cZPHhEjkOP_PRTQXDHEq8AyWpBiJdN9SfQA/s16000/cyclist.gif")
    no-repeat center center;
  width: 88px;
  height: 100px;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 25%;
  animation: car-animation 28s linear infinite;
}
.car {
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEia0PYPxwT5ifToyP3SNZeQWfJEWrUENYA5IXM6sN5vLwAKvaJS1pQVu8mOFFUa_ET4JuHNTFAxKURFerJYHDUWXLXl1vDofYXuij45JZelYOjEFoCOn7E6Vxu0fwV7ACPzArcno1rYuVxGB7JY6G7__e4_KZW4lTYIaHSLVaVLzklZBLZnQw047oq5-Q/s16000/volks.gif")
    no-repeat center center;
  width: 320px;
  height: 105px;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  animation: car-animation 22s linear infinite;
}

@keyframes car-animation {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  .admin-container h1 {
    margin-top: 20%;
  }
  .Login-container {
    width: 90%;
    display: block;
    margin: 25% auto;
  }
  .Login-image img {
    margin-top: 8%;
    width: 100%;
    margin-left: 0;
  }
  .Login-container h1 {
    text-align: center;
  }
  .Login-container input,
  .form-container input,
  textarea {
    width: 100%;
  }
  .form-container button {
    width: 30%;
    margin-top: 2%;
  }
  .admin-form,
  .job-form {
    margin-left: 0;
  }
  .footer {
    display: none;
  }
  .logout-btn {
    width: auto;
    margin-right: 5%;
    position: fixed;
  }
}
@media screen and (min-width: 1024px) {
  .not-found {
    text-align: center;
    position: absolute;
    font-family: "Philosopher", Sans-serif;
    letter-spacing: 1.5px;
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
