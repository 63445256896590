@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

:root {
  --main-hue: 25;
  --main-color: hsl(var(--main-hue), 92%, 54%);
  --main-color-hover: hsl(var(--main-hue), 77%, 48%);
  --light-main-color: hsl(var(--main-hue), 91%, 55%);
  --text-color: #000;
  --nav-color: hsl(var(--main-hue), 17%, 79%);
  --bg-color: #fff;
  --light-text-color: #9ca7b6;
  --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
  --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
  --input-text: #8c9aaf;
  --input-shadow: hsla(var(--main-hue), 92%, 54%, 0.169);
}

.contact {
  width: 100%;
  overflow: hidden;
  background-color: var(--bg-color);
  position: relative;
  z-index: -1;
}

.contact .container {
  height: 100vh;
  max-width: 95%;
  min-height: 700px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.contact .container > div {
  grid-column: 1/2;
  grid-row: 1/2;
}

.left {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 65px 1rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  position: relative;
  z-index: 3;
}

.contact-heading h1 {
  font-weight: 600;
  color: var(--text-color);
  font-size: 3.5rem;
  line-height: 0.9;
  white-space: nowrap;
  margin-bottom: 1.2rem;
}

.contact-heading h1 span {
  color: var(--main-color);
}

.text {
  color: var(--light-text-color);
  line-height: 1.1;
  font-size: 1rem;
}

.text a {
  color: var(--main-color);
}

.text a:hover {
  color: var(--main-color-hover);
  transition: 0.3s;
}

.form-wrapper {
  max-width: 32rem;
}

.contact-form {
  display: grid;
  margin-top: 2.55rem;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 1rem;
}

.input-wrap {
  position: relative;
}

.input-wrap.w-100 {
  grid-column: span 2;
}

.contact-input {
  width: 100%;
  background-color: var(--input-bg);
  padding: 1.5rem 1.35rem calc(0.75rem - 2px) 1.35rem;
  border: none;
  outline: none;
  font-family: inherit;
  border-radius: 20px;
  color: var(--text-color);
  font-weight: 600;
  font-size: 0.95rem;
  border: 2px solid transparent;
  box-shadow: 0 0 0 0px var(--input-shadow);
  transition: 0.3s;
}

.contact-input:hover {
  background-color: var(--input-bg-hover);
}

.input-wrap label {
  position: absolute;
  top: 50%;
  left: calc(1.35rem + 2px);
  transform: translateY(-50%);
  color: var(--light-text-color);
  pointer-events: none;
  transition: 0.25s;
}

.input-wrap .icon {
  position: absolute;
  right: calc(1.35rem + 2px);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 1.25rem;
  transition: 0.3s;
  color: var(--light-text-color);
}

textarea.contact-input {
  resize: none;
  width: 100%;
  min-height: 150px;
}

textarea.contact-input ~ label {
  top: 1.2rem;
  transform: none;
}

textarea.contact-input ~ .icon {
  top: 1.3rem;
  transform: none;
}

.input-wrap.focus .contact-input {
  background-color: var(--bg-color);
  border: 2px solid var(--light-main-color);
  box-shadow: 0 0 0 5px hsla(var(--main-hue) 91%, 55%, 0.11);
}

.input-wrap.focus label {
  color: var(--light-main-color);
}

.input-wrap.focus .icon {
  color: var(--light-main-color);
}

.input-wrap.not-empty label {
  font-size: 0.66rem;
  top: 0.75rem;
  transform: translateY(0);
}

.btn {
  display: inline-block;
  padding: 1.1rem 2rem;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 40px;
  border: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s;
}

.btn:hover {
  background-color: var(--main-color-hover);
}

.right {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  pointer-events: none;
}

.image-wrapper {
  position: relative;
  grid-column: 2;
}

.image-wrapper .img {
  position: absolute;
  height: 130%;
  left: 60%;
  top: 50%;
  transform: translate(-55%, -55%);
}

.dark .image-wrapper .img {
  transform: translate(-65%, -65%) scale(1.7);
}

.wave-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 100%;
  background-color: var(--bg-color);
  filter: blur(0);
}

.wave-wrap svg {
  position: absolute;
  height: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%) scaleX(1);
  left: calc(100% - 2px);
  transform-origin: left;
}

#wave {
  fill: var(--bg-color);
  height: 100%;
}

.dashed-wave {
  position: absolute;
  z-index: 3;
  height: 130%;
  bottom: 60%;
  left: -28px;
  transform: translateY(50%);
}

#dashed-wave {
  fill: none;
  stroke: var(--nav-color);
  stroke-width: 1px;
  stroke-dasharray: 6.5;
  opacity: 0.8;
}

@media screen and (max-width: 1000px) {
  .contact-heading h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    white-space: normal;
  }

  .text {
    font-size: 0.9rem;
  }

  .contact-form {
    display: grid;
    margin-top: 1.9rem;
    column-gap: 0.8rem;
    row-gap: 0.65rem;
  }

  .contact-input {
    border-radius: 17px;
    font-size: 0.87rem;
    padding: 1.5rem 1.2rem calc(0.75rem - 2px) 1.2rem;
  }

  .input-wrap .icon {
    font-size: 1.1rem;
    right: calc(1.2rem + 2px);
  }

  textarea.contact-input ~ .icon {
    top: 1.33rem;
  }

  .input-wrap.focus .contact-input {
    box-shadow: 0 0 0 3px var(--input-shadow);
  }

  .contact-buttons {
    column-gap: 0.8rem;
    margin-top: 0.45rem;
  }

  .btn {
    padding: 1rem 1.5rem;
    font-size: 0.87rem;
  }

  .dashed-wave {
    bottom: 55%;
    left: -10px;
  }

  #dashed-wave {
    stroke-width: 0.7px;
    stroke-dasharray: 6;
  }

  .wave-wrap {
    transform: translateX(-10%);
  }

  .wave-wrap svg {
    height: calc(110% + 10px);
    transform: translate(-5%, -50%);
  }
}

@media screen and (max-width: 650px) {
  header .container {
    height: 50px;
  }

  .left {
    padding: 50px 1.5rem 0;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .form-wrapper {
    width: 100%;
    margin-top: -38%;
    padding: 1rem 0;
  }

  .right {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .contact {
    max-height: 87%;
  }
}

@media screen and (max-width: 440px) {
  .input-wrap {
    grid-column: span 2;
  }

  .contact-buttons {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }

  .contact-heading h1 {
    font-size: 1.9rem;
    line-height: 1;
  }
}
