.main-career {
  background-color: #f5f5f5;
  padding: 5%;
  margin-top: 5%;
}

.content-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.job-openings-container,
.apply-form-container {
  flex: 1;
  margin: 0 20px;
}

.job-openings-container h2 {
  color: #333;
  margin-bottom: 3%;
}

.job-opening-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.job-opening-card {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.job-opening-card h3 {
  color: #333;
}

.job-opening-card p {
  color: #555;
}

.apply-form {
  background-color: #fff;
  padding: 20px;
  margin-top: 4%;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.apply-form h2 {
  color: #333;
}
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    align-items: center;
    padding: 5%;
  }
  .job-openings-container h2 {
    margin-bottom: 4%;
  }
  .apply-form-container h2 {
    margin-bottom: 8%;
  }

  .job-openings-container,
  .apply-form-container {
    width: 100%;
    margin: 20px 0;
  }

  .job-opening-cards {
    grid-template-columns: 1fr;
  }

  .job-opening-card,
  .apply-form {
    padding: 15px;
  }
}
