:root {
  --header-height: 40px;
  --black-color: hsl(220, 24%, 12%);
  --black-color-light: hsl(220, 24%, 15%);
  --black-color-lighten: hsl(220, 20%, 18%);
  --white-color: #fff;
  --nav-links-color: #d83838;
  --body-font: "Montserrat", sans-serif;
  --normal-font-size: 0.938rem;
  --font-regular: 400;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

.icons {
  display: flex;
  position: relative;
  top: 31%;
  justify-content: flex-end;
}

.icons a {
  color: #1888ff;
}

.icons > *:not(:last-child) {
  margin-right: 15px;
}

.icons > *:not(:last-child):hover {
  color: #d83838;
}

.icons > *:nth-child(3):hover {
  color: #d83838;
}

@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white-color);
  box-shadow: 0 2px 16px hsla(220, 32%, 8%, 0.3);
  z-index: var(--z-fixed);
}

.nav {
  height: var(--header-height);
}

.nav__logo,
.nav__burger,
.nav__close {
  color: var(--black-color);
}

.nav__data {
  /* height: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  font-weight: var(--font-semi-bold);
}

.nav__logo i {
  font-weight: initial;
  font-size: 1.25rem;
}

.nav__toggle {
  position: relative;
  width: 32px;
  height: 32px;
  z-index: 999;
}

.nav__burger,
.nav__close {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  margin-top: 10%;
  font-size: 1.25rem;
  cursor: pointer;
  transition: opacity 0.1s, transform 0.4s;
}

.nav__close {
  opacity: 0;
}

@media screen and (max-width: 1118px) {
  .nav__menu {
    position: absolute;
    left: 0;
    top: 2.5rem;
    width: 100%;
    height: calc(100vh - 3.5rem);
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    transition: top 0.4s, opacity 0.3s;
  }
  .nav__menu::-webkit-scrollbar {
    width: 0;
  }
  .nav__list {
    background-color: var(--white-color);
    padding-top: 1rem;
  }
}

.logo {
  max-width: 100px;
  height: auto;
}

.nav__link {
  color: var(--black-color);
  background-color: var(--white-color);
  font-weight: var(--font-semi-bold);
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  transition: background-color 0.3s;
  position: relative;
}

.nav__link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.5px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.nav__link:hover::after,
.nav__link:focus::after {
  background-color: #d83838;
}
.nav__link:hover,
.nav__link:focus {
  transform: scale(1.08);
}

.nav__link:hover,
.nav__link:focus {
  color: #d83838;
}

.show-menu {
  opacity: 1;
  pointer-events: initial;
}

.show-icon .nav__burger {
  opacity: 0;
  transform: rotate(90deg);
}

.show-icon .nav__close {
  opacity: 1;
  transform: rotate(90deg);
}

.dropdown__item {
  cursor: pointer;
}

.dropdown__arrow {
  font-size: 1.25rem;
  font-weight: initial;
  transition: transform 0.4s;
}

.dropdown__link,
.dropdown__sublink {
  padding: 1.25rem 1.25rem 1.25rem 2.5rem;
  display: flex;
  background-color: #fff;
  color: #000;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: var(--font-semi-bold);
  transition: background-color 0.3s;
}

.dropdown__link i,
.dropdown__sublink i {
  font-size: 1.25rem;
  font-weight: initial;
}

.dropdown__link:hover,
.dropdown__sublink:hover {
  background-color: #ff7d20;
}

.dropdown__menu,
.dropdown__submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

.dropdown__item:hover .dropdown__menu,
.dropdown__subitem:hover > .dropdown__submenu {
  max-height: 1000px;
  transition: max-height 0.4s ease-in;
}

.dropdown__item:hover .dropdown__arrow {
  transform: rotate(180deg);
}

.dropdown__add {
  margin-left: auto;
}

@media screen and (max-width: 340px) {
  .container {
    margin-inline: 1rem;
  }
  .nav__link {
    padding-inline: 1rem;
  }
}

@media screen and (min-width: 1118px) {
  .container {
    margin-inline: auto;
  }
  .nav {
    height: calc(var(--header-height) + 2rem);
    display: flex;
    justify-content: space-between;
  }
  .nav__toggle {
    display: none;
  }
  .nav__list {
    height: 100%;
    display: flex;
    column-gap: 3rem;
  }
  .nav__link {
    height: 100%;
    padding: 0;
    justify-content: initial;
    column-gap: 0.25rem;
  }
  .nav__link:hover {
    background-color: transparent;
  }
  .dropdown__item,
  .dropdown__subitem {
    position: relative;
  }
  .dropdown__menu,
  .dropdown__submenu {
    max-height: initial;
    overflow: initial;
    position: absolute;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s, top 0.3s;
  }
  .dropdown__link,
  .dropdown__sublink {
    padding-inline: 1rem 3.5rem;
  }
  .dropdown__subitem .dropdown__link {
    padding-inline: 1rem;
  }
  .dropdown__submenu {
    position: absolute;
    left: 100%;
  }

  .dropdown__item:hover .dropdown__menu {
    opacity: 1;
    width: max-content;
    pointer-events: initial;
    transition: top 0.3s;
  }

  .dropdown__subitem:hover > .dropdown__submenu {
    opacity: 1;
    top: 0;
    pointer-events: initial;
    transition: top 0.3s;
  }
}

@media screen and (max-width: 960px) {
  .icons {
    display: flex;
    top: -90%;
    position: relative;
    justify-content: center;
  }
  .nav__link::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .logo {
    max-width: 80px;
  }
}
